import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3f4ad254 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _17c3de71 = () => interopDefault(import('../pages/b2b/index.vue' /* webpackChunkName: "pages/b2b/index" */))
const _5b95c33c = () => interopDefault(import('../pages/cms/blog.vue' /* webpackChunkName: "pages/cms/blog" */))
const _08104c60 = () => interopDefault(import('../pages/categories/curtains.vue' /* webpackChunkName: "pages/categories/curtains" */))
const _292595c0 = () => interopDefault(import('../pages/surcharge.vue' /* webpackChunkName: "pages/surcharge" */))
const _de9aee1e = () => interopDefault(import('../pages/categories/relax.vue' /* webpackChunkName: "pages/categories/relax" */))
const _421c27dd = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _39d5964e = () => interopDefault(import('../pages/auth/login/index.vue' /* webpackChunkName: "pages/auth/login/index" */))
const _688e6608 = () => interopDefault(import('../pages/auth/login/seller.vue' /* webpackChunkName: "pages/auth/login/seller" */))
const _706c0d0a = () => interopDefault(import('../pages/static/montage.vue' /* webpackChunkName: "pages/static/montage" */))
const _fec92f6e = () => interopDefault(import('../pages/print.vue' /* webpackChunkName: "pages/print" */))
const _ebc77ba4 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _ceee6d82 = () => interopDefault(import('../pages/categories/pleated-blinds.vue' /* webpackChunkName: "pages/categories/pleated-blinds" */))
const _7d677eea = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _182a1a64 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _6be771f8 = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _d4b6c30a = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _28857ee5 = () => interopDefault(import('../pages/products/category.vue' /* webpackChunkName: "pages/products/category" */))
const _1083976e = () => interopDefault(import('../pages/auth/register/index.vue' /* webpackChunkName: "pages/auth/register/index" */))
const _2df556d0 = () => interopDefault(import('../pages/auth/register/seller.vue' /* webpackChunkName: "pages/auth/register/seller" */))
const _085535e2 = () => interopDefault(import('../pages/categories/roller-blinds.vue' /* webpackChunkName: "pages/categories/roller-blinds" */))
const _e7b9049a = () => interopDefault(import('../pages/categories/curtain.vue' /* webpackChunkName: "pages/categories/curtain" */))
const _6c88829c = () => interopDefault(import('../pages/checkout/order/created.vue' /* webpackChunkName: "pages/checkout/order/created" */))
const _5e9c5532 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _d8c08e90 = () => interopDefault(import('../pages/admin/bank-transfer.vue' /* webpackChunkName: "pages/admin/bank-transfer" */))
const _2f93d609 = () => interopDefault(import('../pages/admin/invoice.vue' /* webpackChunkName: "pages/admin/invoice" */))
const _703f0b72 = () => interopDefault(import('../pages/admin/messenger-statistic.vue' /* webpackChunkName: "pages/admin/messenger-statistic" */))
const _4ce3e13c = () => interopDefault(import('../pages/b2b/success.vue' /* webpackChunkName: "pages/b2b/success" */))
const _1360d978 = () => interopDefault(import('../pages/profile/edit.vue' /* webpackChunkName: "pages/profile/edit" */))
const _a110d09a = () => interopDefault(import('../pages/profile/tradeCredit.vue' /* webpackChunkName: "pages/profile/tradeCredit" */))
const _15195d2e = () => interopDefault(import('../pages/profile/comments.vue' /* webpackChunkName: "pages/profile/comments" */))
const _187128a2 = () => interopDefault(import('../pages/messenger.vue' /* webpackChunkName: "pages/messenger" */))
const _0d247562 = () => interopDefault(import('../pages/profile/orders/index.vue' /* webpackChunkName: "pages/profile/orders/index" */))
const _0d9af5ce = () => interopDefault(import('../pages/profile/change-password.vue' /* webpackChunkName: "pages/profile/change-password" */))
const _11887dc8 = () => interopDefault(import('../pages/admin/pricesOnQuantity.vue' /* webpackChunkName: "pages/admin/pricesOnQuantity" */))
const _8328686c = () => interopDefault(import('../pages/admin/offer/add.vue' /* webpackChunkName: "pages/admin/offer/add" */))
const _91924b46 = () => interopDefault(import('../pages/admin/product/add.vue' /* webpackChunkName: "pages/admin/product/add" */))
const _74a18800 = () => interopDefault(import('../pages/admin/sizes/add.vue' /* webpackChunkName: "pages/admin/sizes/add" */))
const _8ad20868 = () => interopDefault(import('../pages/admin/store/edit.vue' /* webpackChunkName: "pages/admin/store/edit" */))
const _1f512f02 = () => interopDefault(import('../pages/admin/profile-edit.vue' /* webpackChunkName: "pages/admin/profile-edit" */))
const _2ef51fd7 = () => interopDefault(import('../pages/admin/filters.vue' /* webpackChunkName: "pages/admin/filters" */))
const _74bd6896 = () => interopDefault(import('../pages/admin/clientGroups/index.vue' /* webpackChunkName: "pages/admin/clientGroups/index" */))
const _ec7cf5b0 = () => interopDefault(import('../pages/admin/spreadsheet.vue' /* webpackChunkName: "pages/admin/spreadsheet" */))
const _ce1d37ce = () => interopDefault(import('../pages/admin/clients/list.vue' /* webpackChunkName: "pages/admin/clients/list" */))
const _7ccc5244 = () => interopDefault(import('../pages/admin/tradeCredit/list.vue' /* webpackChunkName: "pages/admin/tradeCredit/list" */))
const _331d4364 = () => interopDefault(import('../pages/admin/coupons&groupDeal.vue' /* webpackChunkName: "pages/admin/coupons&groupDeal" */))
const _78363a8a = () => interopDefault(import('../pages/admin/marketing/list.vue' /* webpackChunkName: "pages/admin/marketing/list" */))
const _bb039292 = () => interopDefault(import('../pages/admin/tools.vue' /* webpackChunkName: "pages/admin/tools" */))
const _a8e7a576 = () => interopDefault(import('../pages/admin/offer/list.vue' /* webpackChunkName: "pages/admin/offer/list" */))
const _f4e7dd2e = () => interopDefault(import('../pages/admin/cms/page.vue' /* webpackChunkName: "pages/admin/cms/page" */))
const _0724b062 = () => interopDefault(import('../pages/admin/payment.vue' /* webpackChunkName: "pages/admin/payment" */))
const _7f70d6fa = () => interopDefault(import('../pages/admin/cms/post.vue' /* webpackChunkName: "pages/admin/cms/post" */))
const _dc454f88 = () => interopDefault(import('../pages/admin/employees/list.vue' /* webpackChunkName: "pages/admin/employees/list" */))
const _67ba1ddc = () => interopDefault(import('../pages/admin/product/list.vue' /* webpackChunkName: "pages/admin/product/list" */))
const _e6927862 = () => interopDefault(import('../pages/admin/sizes/list.vue' /* webpackChunkName: "pages/admin/sizes/list" */))
const _8e113da8 = () => interopDefault(import('../pages/admin/descriptionTemplates/list.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/list" */))
const _19db085c = () => interopDefault(import('../pages/admin/cms/updatePage.vue' /* webpackChunkName: "pages/admin/cms/updatePage" */))
const _26117d3a = () => interopDefault(import('../pages/admin/cms/updatePost.vue' /* webpackChunkName: "pages/admin/cms/updatePost" */))
const _0e2549ef = () => interopDefault(import('../pages/admin/messenger.vue' /* webpackChunkName: "pages/admin/messenger" */))
const _341eb9fc = () => interopDefault(import('../pages/admin/shipping.vue' /* webpackChunkName: "pages/admin/shipping" */))
const _69dfe908 = () => interopDefault(import('../pages/admin/orders/index.vue' /* webpackChunkName: "pages/admin/orders/index" */))
const _480b7174 = () => interopDefault(import('../pages/admin/change-password.vue' /* webpackChunkName: "pages/admin/change-password" */))
const _6266c2e4 = () => interopDefault(import('../pages/auth/confirm-phone.vue' /* webpackChunkName: "pages/auth/confirm-phone" */))
const _239ac00e = () => interopDefault(import('../pages/admin/groupDeal/add.vue' /* webpackChunkName: "pages/admin/groupDeal/add" */))
const _e49ad65e = () => interopDefault(import('../pages/admin/clientGroups/manage.vue' /* webpackChunkName: "pages/admin/clientGroups/manage" */))
const _1a0b8f94 = () => interopDefault(import('../pages/admin/marketing/add.vue' /* webpackChunkName: "pages/admin/marketing/add" */))
const _5344341a = () => interopDefault(import('../pages/admin/employees/add.vue' /* webpackChunkName: "pages/admin/employees/add" */))
const _1f3201fa = () => interopDefault(import('../pages/admin/descriptionTemplates/add.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/add" */))
const _abb20f6a = () => interopDefault(import('../pages/admin/orders/add.vue' /* webpackChunkName: "pages/admin/orders/add" */))
const _59a5ebb2 = () => interopDefault(import('../pages/admin/marketing/edit.vue' /* webpackChunkName: "pages/admin/marketing/edit" */))
const _6f80eed0 = () => interopDefault(import('../pages/admin/descriptionTemplates/edit.vue' /* webpackChunkName: "pages/admin/descriptionTemplates/edit" */))
const _eaf63488 = () => interopDefault(import('../pages/auth/confirm-email.vue' /* webpackChunkName: "pages/auth/confirm-email" */))
const _38b4684a = () => interopDefault(import('../pages/profile/orders/_id.vue' /* webpackChunkName: "pages/profile/orders/_id" */))
const _1b8885df = () => interopDefault(import('../pages/admin/groupDeal/deals.vue' /* webpackChunkName: "pages/admin/groupDeal/deals" */))
const _f82ff33c = () => interopDefault(import('../pages/admin/groupDeal/edit.vue' /* webpackChunkName: "pages/admin/groupDeal/edit" */))
const _ae03f5d2 = () => interopDefault(import('../pages/admin/clients/details.vue' /* webpackChunkName: "pages/admin/clients/details" */))
const _6b27e30c = () => interopDefault(import('../pages/admin/tradeCredit/details.vue' /* webpackChunkName: "pages/admin/tradeCredit/details" */))
const _8a57569e = () => interopDefault(import('../pages/admin/offer/edit.vue' /* webpackChunkName: "pages/admin/offer/edit" */))
const _bdb500b0 = () => interopDefault(import('../pages/admin/employees/edit.vue' /* webpackChunkName: "pages/admin/employees/edit" */))
const _4929cf04 = () => interopDefault(import('../pages/admin/product/edit.vue' /* webpackChunkName: "pages/admin/product/edit" */))
const _c802298a = () => interopDefault(import('../pages/admin/sizes/edit.vue' /* webpackChunkName: "pages/admin/sizes/edit" */))
const _48e2bce4 = () => interopDefault(import('../pages/admin/orders/_id.vue' /* webpackChunkName: "pages/admin/orders/_id" */))
const _38c5c427 = () => interopDefault(import('../pages/groupDeal.vue' /* webpackChunkName: "pages/groupDeal" */))
const _0032d5bc = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _88d57824 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _28864369 = () => interopDefault(import('../pages/cms/postPage.vue' /* webpackChunkName: "pages/cms/postPage" */))
const _77641146 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link',
  linkExactActiveClass: 'is-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _3f4ad254,
    props: false,
    name: "404___pl"
  }, {
    path: "/b2b",
    component: _17c3de71,
    props: false,
    name: "b2b___pl"
  }, {
    path: "/blog",
    component: _5b95c33c,
    props: false,
    name: "cms-blog___pl"
  }, {
    path: "/curtains",
    component: _08104c60,
    props: false,
    name: "categories-curtains___pl"
  }, {
    path: "/doplata",
    component: _292595c0,
    props: false,
    name: "surcharge___pl"
  }, {
    path: "/fitted-sheets",
    component: _de9aee1e,
    props: false,
    name: "categories-relax___pl"
  }, {
    path: "/kasa",
    component: _421c27dd,
    props: false,
    name: "checkout___pl"
  }, {
    path: "/logowanie",
    component: _39d5964e,
    props: false,
    name: "auth-login___pl"
  }, {
    path: "/logowanie-sprzedawca",
    component: _688e6608,
    props: false,
    name: "auth-login-seller___pl"
  }, {
    path: "/montaz-plisy-wideo-instrukcja",
    component: _706c0d0a,
    props: false,
    name: "static-montage___pl"
  }, {
    path: "/nadruk",
    component: _fec92f6e,
    props: false,
    name: "print___pl"
  }, {
    path: "/panel-sprzedawcy",
    component: _ebc77ba4,
    props: false,
    name: "admin___pl"
  }, {
    path: "/pleated-blinds",
    component: _ceee6d82,
    props: false,
    name: "categories-pleated-blinds___pl"
  }, {
    path: "/product",
    component: _7d677eea,
    props: false,
    children: [{
      path: "/:productName-p:productId(\\d+)",
      component: _182a1a64,
      name: "product___pl"
    }]
  }, {
    path: "/products",
    component: _6be771f8,
    props: false,
    children: [{
      path: "/produkty",
      component: _d4b6c30a,
      name: "products___pl"
    }, {
      path: "/:categoryName-c:categoryId(\\d+)",
      component: _28857ee5,
      name: "products-category___pl"
    }]
  }, {
    path: "/rejestracja",
    component: _1083976e,
    props: false,
    name: "auth-register___pl"
  }, {
    path: "/rejestracja-sprzedawca",
    component: _2df556d0,
    props: false,
    name: "auth-register-seller___pl"
  }, {
    path: "/roller-blinds",
    component: _085535e2,
    props: false,
    name: "categories-roller-blinds___pl"
  }, {
    path: "/vorhang",
    component: _e7b9049a,
    props: false,
    name: "categories-curtain___pl"
  }, {
    path: "/zamowienie-utworzone",
    component: _6c88829c,
    props: false,
    name: "checkout-order-created___pl"
  }, {
    path: "/zapomnialem-hasla",
    component: _5e9c5532,
    props: false,
    name: "auth-forgot-password___pl"
  }, {
    path: "/admin/bank-transfer",
    component: _d8c08e90,
    props: false,
    name: "admin-bank-transfer___pl"
  }, {
    path: "/admin/invoice",
    component: _2f93d609,
    props: false,
    name: "admin-invoice___pl"
  }, {
    path: "/admin/messenger-statistic",
    component: _703f0b72,
    props: false,
    name: "admin-messenger-statistic___pl"
  }, {
    path: "/b2b/success",
    component: _4ce3e13c,
    props: false,
    name: "b2b-success___pl"
  }, {
    path: "/moje-konto/edytuj",
    component: _1360d978,
    props: false,
    name: "profile-edit___pl"
  }, {
    path: "/moje-konto/kredyt-kupiecki",
    component: _a110d09a,
    props: false,
    name: "profile-tradeCredit___pl"
  }, {
    path: "/moje-konto/opinie",
    component: _15195d2e,
    props: false,
    name: "profile-comments___pl"
  }, {
    path: "/moje-konto/wiadomosci",
    component: _187128a2,
    props: false,
    name: "messenger___pl"
  }, {
    path: "/moje-konto/zamowienia",
    component: _0d247562,
    props: false,
    name: "profile-orders___pl"
  }, {
    path: "/moje-konto/zmiana-hasla",
    component: _0d9af5ce,
    props: false,
    name: "profile-change-password___pl"
  }, {
    path: "/panel-sprzedawcy/ceny-od-ilosci",
    component: _11887dc8,
    props: false,
    name: "admin-pricesOnQuantity___pl"
  }, {
    path: "/panel-sprzedawcy/dodaj-oferte",
    component: _8328686c,
    props: false,
    name: "admin-offer-add___pl"
  }, {
    path: "/panel-sprzedawcy/dodaj-produkt",
    component: _91924b46,
    props: false,
    name: "admin-product-add___pl"
  }, {
    path: "/panel-sprzedawcy/dodaj-rozmiar",
    component: _74a18800,
    props: false,
    name: "admin-sizes-add___pl"
  }, {
    path: "/panel-sprzedawcy/edycja-sklepu",
    component: _8ad20868,
    props: false,
    name: "admin-store-edit___pl"
  }, {
    path: "/panel-sprzedawcy/edytuj",
    component: _1f512f02,
    props: false,
    name: "admin-profile-edit___pl"
  }, {
    path: "/panel-sprzedawcy/filtry",
    component: _2ef51fd7,
    props: false,
    name: "admin-filters___pl"
  }, {
    path: "/panel-sprzedawcy/grupy-klientow",
    component: _74bd6896,
    props: false,
    name: "admin-clientGroups___pl"
  }, {
    path: "/panel-sprzedawcy/import-export",
    component: _ec7cf5b0,
    props: false,
    name: "admin-spreadsheet___pl"
  }, {
    path: "/panel-sprzedawcy/klienci",
    component: _ce1d37ce,
    props: false,
    name: "admin-clients-list___pl"
  }, {
    path: "/panel-sprzedawcy/kredyt-kupiecki",
    component: _7ccc5244,
    props: false,
    name: "admin-tradeCredit-list___pl"
  }, {
    path: "/panel-sprzedawcy/kupony-deals",
    component: _331d4364,
    props: false,
    name: "admin-coupons&groupDeal___pl"
  }, {
    path: "/panel-sprzedawcy/marketing",
    component: _78363a8a,
    props: false,
    name: "admin-marketing-list___pl"
  }, {
    path: "/panel-sprzedawcy/narzedzia",
    component: _bb039292,
    props: false,
    name: "admin-tools___pl"
  }, {
    path: "/panel-sprzedawcy/oferty",
    component: _a8e7a576,
    props: false,
    name: "admin-offer-list___pl"
  }, {
    path: "/panel-sprzedawcy/page",
    component: _f4e7dd2e,
    props: false,
    name: "admin-cms-page___pl"
  }, {
    path: "/panel-sprzedawcy/platnosci",
    component: _0724b062,
    props: false,
    name: "admin-payment___pl"
  }, {
    path: "/panel-sprzedawcy/post",
    component: _7f70d6fa,
    props: false,
    name: "admin-cms-post___pl"
  }, {
    path: "/panel-sprzedawcy/pracownicy",
    component: _dc454f88,
    props: false,
    name: "admin-employees-list___pl"
  }, {
    path: "/panel-sprzedawcy/produkty",
    component: _67ba1ddc,
    props: false,
    name: "admin-product-list___pl"
  }, {
    path: "/panel-sprzedawcy/rozmiary",
    component: _e6927862,
    props: false,
    name: "admin-sizes-list___pl"
  }, {
    path: "/panel-sprzedawcy/szablony-opisow",
    component: _8e113da8,
    props: false,
    name: "admin-descriptionTemplates-list___pl"
  }, {
    path: "/panel-sprzedawcy/updatePage",
    component: _19db085c,
    props: false,
    name: "admin-cms-updatePage___pl"
  }, {
    path: "/panel-sprzedawcy/updatePost",
    component: _26117d3a,
    props: false,
    name: "admin-cms-updatePost___pl"
  }, {
    path: "/panel-sprzedawcy/wiadomosci",
    component: _0e2549ef,
    props: false,
    name: "admin-messenger___pl"
  }, {
    path: "/panel-sprzedawcy/wysylki",
    component: _341eb9fc,
    props: false,
    name: "admin-shipping___pl"
  }, {
    path: "/panel-sprzedawcy/zamowienia",
    component: _69dfe908,
    props: false,
    name: "admin-orders___pl"
  }, {
    path: "/panel-sprzedawcy/zmiana-hasla",
    component: _480b7174,
    props: false,
    name: "admin-change-password___pl"
  }, {
    path: "/telefon/potwierdzenie",
    component: _6266c2e4,
    props: false,
    name: "auth-confirm-phone___pl"
  }, {
    path: "/panel-sprzedawcy/groupDeal/dodaj",
    component: _239ac00e,
    props: false,
    name: "admin-groupDeal-add___pl"
  }, {
    path: "/panel-sprzedawcy/grupy-klientow/zarzadzaj",
    component: _e49ad65e,
    props: false,
    name: "admin-clientGroups-manage___pl"
  }, {
    path: "/panel-sprzedawcy/marketing/dodaj",
    component: _1a0b8f94,
    props: false,
    name: "admin-marketing-add___pl"
  }, {
    path: "/panel-sprzedawcy/pracownicy/dodaj",
    component: _5344341a,
    props: false,
    name: "admin-employees-add___pl"
  }, {
    path: "/panel-sprzedawcy/szablony-opisow/dodaj",
    component: _1f3201fa,
    props: false,
    name: "admin-descriptionTemplates-add___pl"
  }, {
    path: "/panel-sprzedawcy/zamowienia/dodaj",
    component: _abb20f6a,
    props: false,
    name: "admin-orders-add___pl"
  }, {
    path: "/panel-sprzedawcy/marketing/edycja/:id",
    component: _59a5ebb2,
    props: false,
    name: "admin-marketing-edit___pl"
  }, {
    path: "/panel-sprzedawcy/szablony-opisow/edycja/:id",
    component: _6f80eed0,
    props: false,
    name: "admin-descriptionTemplates-edit___pl"
  }, {
    path: "/email/potwierdzenie/:token",
    component: _eaf63488,
    props: false,
    name: "auth-confirm-email___pl"
  }, {
    path: "/moje-konto/zamowienie/:id",
    component: _38b4684a,
    props: true,
    name: "profile-orders-id___pl"
  }, {
    path: "/panel-sprzedawcy/deals/:groupDeal",
    component: _1b8885df,
    props: false,
    name: "admin-groupDeal-deals___pl"
  }, {
    path: "/panel-sprzedawcy/groupDeal/:id",
    component: _f82ff33c,
    props: false,
    name: "admin-groupDeal-edit___pl"
  }, {
    path: "/panel-sprzedawcy/klient/:id",
    component: _ae03f5d2,
    props: false,
    name: "admin-clients-details___pl"
  }, {
    path: "/panel-sprzedawcy/kredyt-kupiecki/:id",
    component: _6b27e30c,
    props: false,
    name: "admin-tradeCredit-details___pl"
  }, {
    path: "/panel-sprzedawcy/oferta/:id",
    component: _8a57569e,
    props: false,
    name: "admin-offer-edit___pl"
  }, {
    path: "/panel-sprzedawcy/pracownicy/:id",
    component: _bdb500b0,
    props: false,
    name: "admin-employees-edit___pl"
  }, {
    path: "/panel-sprzedawcy/produkt/:id",
    component: _4929cf04,
    props: false,
    name: "admin-product-edit___pl"
  }, {
    path: "/panel-sprzedawcy/rozmiar/:id",
    component: _c802298a,
    props: false,
    name: "admin-sizes-edit___pl"
  }, {
    path: "/panel-sprzedawcy/zamowienie/:id",
    component: _48e2bce4,
    props: true,
    name: "admin-orders-id___pl"
  }, {
    path: "/groupDeal/:identify",
    component: _38c5c427,
    props: false,
    name: "groupDeal___pl"
  }, {
    path: "/reset-hasla/:token",
    component: _0032d5bc,
    props: false,
    name: "auth-reset-password___pl"
  }, {
    path: "/",
    component: _88d57824,
    props: false,
    name: "index___pl"
  }, {
    path: "/:slug",
    component: _28864369,
    props: false,
    name: "cms-postPage___pl"
  }, {
    path: "/*",
    component: _77641146,
    props: false,
    name: "*___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
