export default function ({app}, inject) {
  window.dataLayer = window.dataLayer || []

  function gtag () {
    dataLayer.push(arguments)
    if (process.env.NODE_ENV === 'development') {
      console.log('gtag', arguments)
    }
  }

  let cookieData = localStorage.getItem('cookieAlertV2')

  if (cookieData) {
    cookieData = JSON.parse(cookieData)
    gtag('consent', 'default', {
      'ad_storage': cookieData.advertisement === false ? 'denied' : 'granted',
      'ad_user_data': cookieData.advertisement === false ? 'denied' : 'granted',
      'ad_personalization': cookieData.advertisement === false ? 'denied' : 'granted',
      'analytics_storage': cookieData.analytics === false ? 'denied' : 'granted',
    });
  } else {
    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied'
    });
  }

  inject('gtag', gtag)
  gtag('js', new Date())
  gtag('config','AW-666487120',)

  // if(!true){
  //   router.afterEach((to) => {
  //     gtag('config', 'AW-666487120', {'page_path': to.fullPath})
  //   })
  // }

  // additional accounts

    gtag('config', 'G-EB9FDB9EW4',{})
}
